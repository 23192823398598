import React from "react"
import IntegrationTemplate from "../../templates/integration"
import * as Ui from "components/ui"
import * as Sections from 'components/sections'
import * as Layout from 'components/layout'
import IntegrationStorageContent from 'components/content/integration-storage'
import { meta } from '../../configs/storage-providers';
const ContentPage = () => (
  <IntegrationTemplate
    key="wasabi"
    name="Wasabi"
    shortDescription="Send your server file and database backups to Wasabi.Secure backups on Wasabi like a breeze!"
    website={"https://wasabi.com/"}
    links={[{text: 'Documentation', url: '/docs'}]}
    category="Storage"
    logo="wasabi-ico.png"
    metaTitle={meta.title.replace('##provider##', 'Wasabi')}
    metaDescription={meta.description.replace('##provider##', 'Wasabi')}
  >
    <IntegrationStorageContent
      keyRef="wasabi"
      service="Wasabi"
      provider="Wasabi"
      learnTags={['wasabi']}
      contentBlocks={[{title: 'Minimal Wasabi access is needed', text: 'When you connect SimpleBackups to your Wasabi account, SimpleBackups is only given access to its own application folder. Meaning that access is only limited to that folder on Wasabi and not your whole storage.\n'}]}
    />
  </IntegrationTemplate>
)

export default ContentPage
